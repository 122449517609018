import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import * as React from 'react';

import {
  HeadMeta,
  IInfoLink,
  IMainPost,
  IMenuLink,
  INextPrev,
  Info,
  Layout,
  NextPrev,
  ReferencesHero,
} from '../../components';
import { RichTextRender } from '../../components/RichTextRender';
import { LanguageContext } from '../../context/LanguageContext';
import { RichTextElements } from '../../utils/fragments/types';
import {
  IContentfulVacationBanner,
  ILocation,
  IMetaTagField,
} from '../../utils/types';

import * as styles from './ArticleComponent.module.less';

interface IArticleComponent {
  menuLinks: IMenuLink[];
  infoLinks: IInfoLink[];
  title: string;
  meta?: IMetaTagField[];
  mainPost: IMainPost;
  staticLocation?: ILocation;
  details?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  content: RenderRichTextData<RichTextElements>;
  articleType?: 'reference' | 'karriere' | 'news' | 'forderungen';
  date?: string;
  prevPost?: INextPrev;
  nextPost?: INextPrev;
  language: 'De' | 'En';
  vacationBanner?: IContentfulVacationBanner;
}

export const ArticleComponent: React.FunctionComponent<IArticleComponent> = (
  props
) => {
  return (
    <LanguageContext.Provider value={props.language}>
      <Layout
        links={props.menuLinks}
        infoLinks={props.infoLinks}
        vacationBanner={props.vacationBanner}
      >
        <HeadMeta title={props.title} meta={props?.meta} lang={props.language.toLowerCase()} />
        <ReferencesHero
          mainPost={props.mainPost}
          location={props.staticLocation}
        />
        <div className={styles.container}>
          {props.date && <span>{props.date}</span>}
          {props.details && <Info data={props.details} />}
          <RichTextRender content={props.content} />
        </div>
        <NextPrev
          sectionName={props.articleType}
          prevPost={props.prevPost}
          nextPost={props.nextPost}
        />
      </Layout>
    </LanguageContext.Provider>
  );
};
