import { graphql } from 'gatsby';
import * as React from 'react';

import { formatInfoLinks, formatMenuLinks } from '../../utils/helpers';
import { IArticleTemplateProps } from '../../utils/types';

import { ArticleComponent } from './ArticleComponent';

const ArticleTemplate = ({
  data,
  location,
  pageContext,
}: IArticleTemplateProps): React.ReactElement => {
  const {
    title,
    featuredImage,
    tags,
    details,
    content,
    meta,
    articleType,
    language,
  } = data.contentfulArticle;

  const mainPost = {
    title: title,
    featuredImage: featuredImage,
    tags: tags,
  };

  const menuLink = formatMenuLinks(data.allContentfulCategory.nodes, language);
  const infoLink = formatInfoLinks(
    data.allContentfulPrivacyAndToS.nodes,
    language
  );

  return (
    <ArticleComponent
      menuLinks={menuLink}
      infoLinks={infoLink}
      title={title}
      meta={meta && meta}
      mainPost={mainPost}
      staticLocation={location}
      details={details}
      content={content}
      articleType={articleType}
      nextPost={pageContext.next}
      prevPost={pageContext.prev}
      language={language}
      vacationBanner={data.contentfulVacationBanner}
    />
  );
};

export const query = graphql`
  query articleQuery($slug: String, $language: String) {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        ...category
      }
    }
    contentfulArticle(slug: { eq: $slug }) {
      contentful_id
      language
      title
      articleType
      featuredImage {
        contentful_id
        title
        gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
      }
      tags {
        slug
        title
      }
      meta {
        name
        content
      }
      content {
        raw
        references {
          ... on Node {
            ...allBlocks
          }
        }
      }
      details {
        raw
      }
    }
    allContentfulPrivacyAndToS(filter: { language: { eq: $language } }) {
      nodes {
        title
        language
        url: slug
      }
    }
    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default ArticleTemplate;
